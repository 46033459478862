.div_boton-fixed {
  position: sticky !important;
  bottom: 0 !important;
  background-color: #efefef;
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
  padding-top: 0.1vw;
}

.dialog-freelance {
  .p-dialog-content {
    padding-bottom: 0 !important;
  }
}

.textarea-freelance {
  height: 80px !important;
}

.boton-informe-freelance {
  padding: 0 5%;
}
