body {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

.custom-container {
  width: 65%;
  position: absolute;
  margin-top: 24px;
}

.p-card-content {
  padding: 0rem;
}

.custom-summary-card {
  height: 300px;
  overflow-y: scroll;
}

@media (min-height: 780px) {
  .custom-summary-card {
    height: 420px;
    overflow-y: hidden
  }
}

.custom-summary-card h2 {
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif !important;
  color: var(--black-color) !important;
}

.custom-summary-card p {
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif !important;
}

.custom-summary-card .custom-details {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.custom-summary-card .custom-details td {
  font-family: "Montserrat", sans-serif !important;
  padding: 8px 0px;
  border: none;
}

.custom-summary-card .custom-details td:nth-child(odd) {
  font-weight: bold;
}

.td-title {
  font-size: 13px;
}

.td-subtitle {
  font-size: 13px;
}

.custom-footer {
  display: inline-block;
  justify-content: center;
  align-items: flex-start;
  margin: 9px 0px 0px 230px;
  background-color: #f9f9f9;
  gap: 10px;
  position: fixed;
  bottom: 0;
}

.custom-footer .p-button {
  margin: 10px;
  padding: 10px 24px;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  background-color: #005bac;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.custom-footer .p-splitbutton {
  margin: 10px;
  padding: 4px 20px;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  background-color: #005bac !important;
  color: #ffffff !important;
  border: none !important;
  cursor: pointer !important;
}

.custom-footer .p-splitbutton .p-button {
  margin: 0;
  padding: 0;
}

.custom-summary-card .p-card-body {
  padding: 16px 40px !important;
}
