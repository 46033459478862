.p-button .short-button,
button.p-button.p-component.short-button,
button.p-button.short-button.p-disabled {
  width: fit-content !important;
  padding: 0 10px;
}

button.p-button.p-component.p-autocomplete-dropdown.p-button-icon-only {
  border-radius: 0 6px 6px 0;
}

.p-autocomplete-loader {
  display: none;
}

.form-subtitle {
  margin: 1rem 0 -1.3rem 0;
}

.cont-recursos{
  margin-top: 40px !important;
}

#row-container-form .p-dropdown-trigger{
  width: 16px;
  padding-right: 12px;
}

#row-container-form .pi-chevron-down {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}