.form-cecos {
    gap: 10px;
    margin-top: 1.8rem;
}

.p-button .short-button,
button.p-button.p-component.short-button,
button.p-button.short-button.p-disabled {
    width: fit-content !important;
    padding: 0 10px;
}

button.p-button.p-component.p-autocomplete-dropdown.p-button-icon-only {
    border-radius: 0 6px 6px 0;
}

.p-autocomplete-loader {
    display: none;

}

.form-subtitle {
    margin: 0.5rem 0;
}

.cont-cecos{
    margin-top: 40px !important;
}