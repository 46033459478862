.dialog-title {
    font-size: 24px;
    font-family: "Montserrat";
    font-weight: 700;
    text-align: justify;
    padding-left: 2rem;
    margin-top: 35px;
}

.dialog-subtitle {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: bold;
    text-align: justify;
    margin-bottom: 2rem;
    padding-left: 2rem;
    font-weight: 500;
}

.dialog-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 2rem;
}

.dialog-content .p-field {
    flex: 0 0 48%;
    margin-bottom: 1rem;
}

.campos-modal {
    width: 250px;
    height: 35px;
}

.boton-footer-cancelar-modal {
    width: 124px;
    height: 30px;
    font-family: "Montserrat"!important;
    font-weight: 600!important;
    font-size: 14px!important;
    letter-spacing: 0.1px!important;
}

.boton-footer-aplicar-modal {
    width: 124px;
    height: 30px;
    font-family: "Montserrat"!important;
    letter-spacing: 0.1px!important;
    font-weight: 600!important;
    font-size: 14px!important;
}

#custom-bg-modal.p-dialog {
    background-color:#fff !important;
  }
  #custom-bg-modal_content{
      background-color:#fff !important;
  }
  #custom-bg-modal_header{
      background-color:#fff !important;
  }
  #custom-bg-modal .p-dialog-header{background-color:#fff !important;}