.dialog-modal {
  display: flex;
  width: 743px;
  height: 288px;
  border-radius: 16px;
}

#ciruculo-exclamacion-modal-home {
  font-size: 44px;
  gap: 0px;
  opacity: 0px;
  top: 20px;
}

.p-modal-home {
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  text-align: left;
  margin-top: 40px;
}

.button-cancelar-modal {
  background-color: transparent;
  color: #79747e;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  height: 40px;
  width: 140px;
  cursor: pointer;
  margin: 20px 10px 0 0;

  &:hover {
    background-color: transparent;
  }
}

.button-editar-modal {
  width: 120px;
  color: #fff;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  height: 40px;
  width: 140px;
  cursor: pointer;
  border-radius: 4px;
  margin: 20px 0 0 0;

  &:hover {
    background-color: #4f46e5;
  }
}
