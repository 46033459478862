.button-volver-inicio {
    font-family: 'Montserrat' !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    background: white !important;
    color: rgba(115, 115, 115, 1) !important;
    margin: 0px 0px 0px 18px !important;
    text-transform: none !important;

    &:hover {
        border: none;
    }
}

.button-volver-inicio .pi {
    width: 30px;
    margin: 0px !important;
}

.div-imagen-cotizador {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.content-wrapper {
    margin-bottom: 20px;
}

.text-h1 {
    font-family: 'Montserrat' !important;
    font-weight: 700px !important;
    font-size: 24px !important;
    line-height: 39.01px !important;
    padding: 8px 0px 8px 0px;
    margin-left: 12px;
    line-height: 39.01px;
}

.title {
    color: black;
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 29.26px !important;
    padding-top: 20px;
}

.button-cotizar {
    text-align: center;
    width: 236px;
    height: 40px;
    border-radius: 4px;
    gap: 8px;
}

.button-cotizar .pi {
    width: 30px;
    margin: 0px -20px 0px 10px !important;
}

.select-clientes-home {
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    color: rgba(76, 78, 100, 1) !important;
}

.p-dropdown-label.p-inputtext {
    font-size: 15px !important;
}

.selected-client {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: rgba(8, 75, 250, 1);
    font-size: 14px;
    margin-right: 10px;
    text-align: center;
    Padding: 2px 6px 2px 11px;
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: rgba(255, 255, 255, 1);
}

.pi-search{
    top: 10px !important;
}

.p-cards{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
}

.user-card{
    display: flex;
    height: 34px;
    padding: 5px 15px 5px 15px;
    gap: 10px;
    border-radius: 4px;
    background: #4C4E64;
    max-width: 270px;
    position: absolute;
    bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
}

#pi-user-card-cotizador-home{
    color: #FFF;
    padding: 0 16px 0 2px;
    margin: 0px;
    width: 10px;
}
.p-user-card{
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.panel-editar-eliminar {
    position: absolute;
    top: -53px;
    right: -24px;
    width: 154px;
    height: 88px;
}

/* contenido del overlay */

.p-overlaypanel-content{
    margin: 0px !important;
    padding: 4px 0px !important;
}

.menu-opciones-home{
    display: inline-block;
    flex-direction: column;
}

.p-button-edit-delete{
    background: none !important;
    color: #000 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    font-family: 'Montserrat' !important;
    width: 100% !important;
    height: 44px !important;
    padding: 5px 0px !important;
    border-radius: 0px !important;

    &:hover{
        background: #954E4E33 !important;
    }
}

#pencil-edit-home{
    font-size: 16px;
    width: 42px;
    padding-left: 6px;
}

#trash-delete-home{
    font-size: 16px;
    width: 42px;
    padding: 0 0 0 6px;
}
