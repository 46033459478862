.pi {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  font-size: 20px;
}

.pi:before {
  vertical-align: -webkit-baseline-middle;
}

.p-accordion-tab,
.p-accordion-content {
  display: flex;
  flex-direction: column;
  line-height: 2;
}

.p-accordion-content a:hover {
  color: var(--black-color);
}

.p-accordion-header-link {
  flex-direction: row-reverse;
  color: var(--white-color);
  justify-content: flex-end;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0;
  border: none;
  color: white;
  background: transparent;
  font-weight: 100;
  border-radius: 0;
  transition: box-shadow 0.2s;
}

nav.main-menu.expanded {
  width: 270px;
  overflow: visible;
  transition: 0.3s;
}

.main-menu {
  top: 0;
  bottom: 0;
  height: 100vh;
  left: 0;
  width: 60px;
  overflow: hidden;
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
  transform: translateZ(0) scale(1, 1);
  z-index: 1000;
  border-top-right-radius: 25px;
  background: rgb(8, 75, 250);
  background: linear-gradient(
    145deg,
    rgba(8, 75, 250, 1) 0%,
    rgba(26, 208, 251, 1) 77%,
    rgba(44, 238, 204, 1) 100%
  );
  font-family: var(--main-font);
  z-index: 99;
  position: fixed;
}

.main-menu > ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
  margin: 8px 0;
  /* cursor: pointer; */
}
.main-menu li > a {
  cursor: pointer;
}

.main-menu li > a,
.main-menu li > div {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #ffffff;
  font-family: var(--main-font);
  font-size: 14px;
  text-decoration: none;
  transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  text-decoration: none;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  /* width:190px; */
  font-family: var(--main-font);
  text-align: initial;
}

.main-menu > ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.main-menu li:hover > .item-menu,
.main-menu li:hover > a > .pi-book,
.main-menu li:hover > a > .pi-briefcase,
nav.main-menu li.active > a,
.main-menu li:hover .p-accordion-header-link,
.pi-cog:hover {
  color: var(--black-color);
}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}

.name-user {
  color: var(--white-color);
}

.card-expanded {
  width: 100%;
  background-color: #f5f5f575;
  height: 100vh;
  z-index: 99;
  position: fixed;
  opacity: 1;
}

.avatar-custom {
  display: flex !important;
}

.nav-text.name-user {
  text-align: start;
  padding-left: 11px;
  text-transform: lowercase;
  width: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-menu .pi-cog {
  color: var(--white-color);
}

.item-menu .pi-cog:hover {
  color: var(--black-color);
}

.botonConfig {
  position: fixed;
}

.material-symbols-outlined{
  font-size: 20px;
}
