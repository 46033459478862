.viewHsTooltip{
  z-index: 10000;
  padding: 1rem;
  border-radius: 5px;
}

.modal-calendar {
  position: fixed;
  top: 11%;
  right: 0;
  bottom: 10%;
  width: 40vw;
  height: 85vh;
  max-height: 100vh;
  background: var(--background-grey) !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 17px;
  z-index: 1000;
  overflow: auto;
}

@media screen and (max-width: 1300px) {
  .modal-calendar {
    width: 50vw;
  }
}

.rbc-event-allday {
  background-color: white;
  border-radius: 0;
  margin-bottom: 2px;
}

.rbc-day-bg {
  border-top: solid 1.5px rgb(68, 255, 47);
  border-bottom: solid 1.5px rgb(68, 255, 47);

  &:nth-child(7n) {
    border-top: 1.5px solid red;
    /* Domingos */
    border-bottom: 1.5px solid red;
  }

  &:nth-child(6n) {
    border-top: 1.5px solid red;
    /* Sábados */
    border-bottom: 1.5px solid red;

  }
}

.total-horas {
  height: 39px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 650px;
}

.input-calendar-month {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: rgb(19 21 26);
  border: 1px solid black;
  text-align: center;
  background: #dddada9c;
  border-radius: 5px;
  height: 30px;
  padding: 2px;
  width: 220px
}

.input-calendar-month::-webkit-calendar-picker-indicator {
  cursor: pointer;
  font-size: 25px;
}

.button-modal-calendar {
  cursor: pointer;
  width: 25%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 30px;
  font-weight: 700;

  &:hover {
    background: rgb(18, 73, 211);
  }
}

.button-cancelar-calendar {
  background: #d83636;

  &:hover {
    background: #b50303;
  }
}

.button-si-calendar {
  width: 100px;
  height: 30px;
  border-radius: 5px;
  color: white;
  background: rgb(18, 81, 239);
  margin-left: 20px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #0838b0;
  }
}

.button-no-calendar {
  width: 100px;
  height: 30px;
  border-radius: 4px;
  color: #6366F1;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rbc-calendar {
  width: 100%;
  height: 93%;
}

.div-select-proyectos-calendar {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #ced4da;
}

.p-nombre-proyecto-calendar {
  font-size: 30px;
  font-weight: 700;
}

.tr-calendar {
  text-align: center;
}

.select-proyecto-calendar {
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 14px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #495057;
}

.select-tarea-calendar {
  display: flex;
  margin-top: 4px;
  width: 100%;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #495057;

  &:hover {
    border: 1px solid rgb(18, 73, 211);
    ;
  }
}

.input-horas-calendar {
  margin-top: 4px;
  height: 30px;
  padding-left: 3px;
  width: 100%;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #495057;
  border-radius: 5px;
  border: 1px solid #b5aaaa;

  &:hover {
    border: 1px solid rgb(18, 73, 211);
    ;
  }
}
.input-horas-calendar::-webkit-calendar-picker-indicator {
  cursor: pointer;
  font-size: 20px;
}


.input-horas-calendar:disabled .text-area-detalles-calendar:disabled {
  background: #ffffff;
}

.text-area-detalles-calendar {
  width: 100%;
  height: 60px;
  font-size: 14px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #495057;
}

.tooltip-calendar {
  color: rgb(106 109 118);
  padding-right: 10px;
  cursor: pointer;
}

.contenido-tooltip {
  color: rgb(18, 81, 239);
  background: rgb(218, 215, 215);
  display: flex;
  font-size: 16px;
  font-weight: 500;
}

.buttons-switch-calendar {
  width: 100%;
  color: rgba(21, 67, 194, 0.979);
  background-color: #ffff;
  letter-spacing: 1px;
  font-size: 19px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-radius: 5px;
  margin-right: 10px;

  &:hover {
    border-bottom: 2px solid rgb(18, 81, 239);
    background-color: #ffff;
  }
}

.active-button {
  background-color: #3d6bb3;
  color: white;

  &:hover {
    background-color: #3d6bb3;
  }
}

.mini-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 260px;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.mini-modal button {
  margin-top: 10px;
  cursor: pointer;
}

.mini-modal h2 {
  text-align: center;
  font-size: 18px;
  text-transform: capitalize
}

.mini-modal ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow-y: auto;
  max-height: 160px;
  max-height: 168px;
  padding-right: 2px
}

.mini-modal li {
  margin-bottom: 5px;
  text-align: justify;
  cursor: pointer;
  border: 1px solid #3174ad;
  background: #3174ad;
  color: #ffff;
  border-radius: 5px;
  padding: 2px;
  transition: all 0.3s;

}

.mini-modal li:hover {
  background: #3d6bb3
}


.mini-modal li.selected {
  background: #3d6bb3
}

.mini-modal p {
  text-align: center;
  margin-bottom: 5px;
  font-size: 19px;
}

.rbc-show-more:hover {
  background: #337ab7;
  color: #ffff;
  border-radius: 4px;
}

.select-opton-calendar {
  padding: 2px;
}

.modal-eliminar-calendar {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 306px;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: white;
  padding: 5px;
  border-radius: 5px
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dos-puntos {
  padding: 3px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.reporte{
  background: #8080804a;
  height: 25px;
  width: 175px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #80808075;
  }
}

.modal-reporte {
  width: 320px;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: var(--background-grey) !important;
}

.color-button{
  color: var(--button-primary-blue);
}

@keyframes dotAnimation {
  0% { content: ""; }
  33% { content: "."; }
  66% { content: ".."; }
  100% { content: "..."; }
}

.button-modal-calendar .dots::after {
  display: inline-block;
  animation: dotAnimation 1s steps(4, end) infinite;
  content: "";
}
