.calculo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 9px 0 3px 0;
  background-color: #f9f9f9;
  gap: 10px;
  position: fixed;
  bottom: 0;
  width: 94%;
}

.costo-cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card-item {
  height: 77px;
  flex: 1 1 200px;
  width: 150px;
}

@media (min-width: 1910px) {
  .card-item {
    width: 215px !important;
  }
}

@media (max-width: 1340px) {
  .card-item {
    width: 140px !important;
  }
}

@media (max-width: 1480px) {
  .card-item {
    width: 150px;
  }
}

@media (max-width: 1270px) {
  .card-item {
    width: 130px;
  }
}

@media (min-width: 1500px) {
  .card-item {
    width: 170px;
  }
}

@media (min-width: 1700px) {
  .card-item {
    width: 170px;
  }
}

.porcentaje-rentabilidad {
  background: rgba(1, 236, 165, 0.05);
  color: #00b374;
  left: 100px;
  padding: 0px 1px;
  margin-left: 2px;
  font-weight: 500;
  font-size: 10px;
  align-content: center;
  z-index: 1;
}

.porcentaje-rentabilidad.rojo {
  color: red;
}

.continuar-button {
  padding: 10px 20px;
  background-color: #084bfa;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  flex: 0 0 138px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Montserrat";
  margin-top: 10px;

  &:hover {
    background-color: #6366f1;
  }
}

.p-card .p-card-title {
  font-size: 14px !important;
}

.p-card-body {
  height: 100% !important;
  width: 100% !important;
}

.p-card .p-card-content {
  padding: 0 !important;
}

.p-card .p-card-content h3 {
  color: #394e75;
  font-size: 15px;
}

.p-card .p-card-title {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1024px) {
  .card-item {
    flex: 1 1 45%;
  }

  .continuar-button {
    flex: 1 1 45%;
  }
}

@media (max-width: 600px) {
  .p-card .p-card-title {
    font-size: 0.9rem;
  }

  .card-item {
    flex: 1 1 100%;
  }

  .continuar-button {
    flex: 1 1 100%;
  }
}

.selectButtonMonedas {
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  font-size: 14px !important;
}

.selectButtonMonedas .p-button {
  width: 40px;
}

.selectButtonMonedas .p-button.p-highlight {
  background-color: rgba(6, 12, 22, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
  border-color: rgba(6, 12, 22, 1) !important;
}
