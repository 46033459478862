.final-submit-modal button {
  padding: 0 10px !important;
  border-radius: 30px !important;
  height: 30px;
}

.final-submit-modal {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

#motivo {
  width: 100%;
}
