.form-nuevo-cc .p-fluid.grid {
  display: flex;
  align-items: center;
  margin-top: 6px;
  gap: 10px;
}

.form-nuevo-cc .p-fluid.grid .field {
  width: 45%;
}

.p-float-label label {
  font-size: 13px;
  /* top: 32%; */
}

span.p-dropdown-label.p-inputtext {
  padding: 6px 12px !important;
}

.p-fluid .p-button {
  border-radius: 30px;
  height: 30px;
}

.p-fluid .p-button:hover {
  background-color: var(--button-primary-hover);
}

.form-nuevo-cc h4 {
  margin: 10px 0;
}
