.labelRetroactivo {
    margin-left: 40px;
}

.inputSwRetroactivo {
    margin-left: 165px;
}

.coment{
    width: 220% !important;
}
.visibleRecursos{
    background-color: rgba(59, 59, 59, 0.745);
    color: aliceblue;
    width: 20vw;
    height: 50vh;
    position: absolute;
    left: -40%;
    top: 15%;
    overflow-y: auto;
    direction: rtl;
    text-align: end;
    border-radius: 5px 0px 5px 5px;
    animation: entrance 700ms ease 0s 1 normal forwards;
    z-index: 1;
}

.titleRecursosFromCeco{
    background-color: rgb(26, 26, 26);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7%;
    z-index: 2;
}

@keyframes entrance {
    0% {
        opacity: 0;
		transform: rotateY(-100deg);
		transform-origin: right;
	}
    
	100% {
        opacity: 1;
		transform: rotateY(0);
		transform-origin: right;
	}
}

.notVisible{
    background-color: rgba(59, 59, 59, 0.745);
    color: aliceblue;
    width: 20vw;
    height: 50vh;
    position: absolute;
    left: -40%;
    top: 15%;
    overflow-y: auto;
    direction: rtl;
    text-align: end;
    border-radius: 5px 0px 5px 5px;
    z-index: 1;
    animation: exit 250ms ease 0s 1 normal both;
}
@keyframes exit {
	0% {
		opacity: 1;
		transform: rotateY(0);
		transform-origin: right;
	}

	100% {
		opacity: 0;
		transform: rotateY(70deg);
		transform-origin: right;
	}
}
