.titulo-calendar {
  width: 100%;
  text-align: start;
  font-size: 13px;
}

.container-fecha {
  margin: 0;
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
}

.input-container-fecha {
  display: flex;
  flex-direction: column;
  margin: 3%;
}

.boton-informe {
    display: flex;
    /* width: 200px; */
    justify-content: center;
    flex-wrap: wrap;
    /* margin-top: 25px;
      margin-bottom: 30px; */
    padding: 3%;
  }