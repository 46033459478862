:root {
  --primary-color: #084bfa;
  --secondary-color: #1ad0fb;
  --third-color: #2ceecc;
  --white-color: #ffffff;
  --black-color: #111110;
  --background-grey: #efefef;

  --button-primary-blue: #084bfa;
  --button-primary-hover: #1ad0fb;
  --button-primary-disabled: #87a4d2;

  --main-font: "Montserrat", sans-serif;
}
