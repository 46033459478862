.form-pago-prov .p-fluid.grid {
  display: flex;
  align-items: center;
  margin-top: 6px;
  gap: 10px;
}

.form-pago-prov .p-fluid.grid .field {
  width: 45%;
}

.p-float-label label {
  font-size: 13px;
  /* top: 32%; */
}

.p-dropdown,
.p-inputtext,
#proveedor button {
  height: 30px;
}

span.p-dropdown-label.p-inputtext {
  padding: 6px 12px !important;
}

.p-fluid .p-button {
  border-radius: 30px;
  height: 30px;
}

.p-fluid .p-button:hover {
  background-color: var(--button-primary-hover);
}

#proveedor button {
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-autocomplete-panel {
  font-size: 13px;
}

.button-icon .pi {
  color: var(--button-primary-blue);
}

.button-icon:hover .pi {
  color: var(--button-primary-hover) !important;
}

.p-dialog-title {
  color: var(--button-primary-blue);
}

.switch {
  align-items: center;
  display: flex;
}

.switch .p-inputswitch {
  margin-left: 5px;
}

.control-title {
  font-weight: 600;
  color: var(--button-primary-blue);
} 