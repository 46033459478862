.campoResumen {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.campoResumen::before {
    content: 'MAS INFO';
    font-size: 13px;
    font-weight: 800;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20%;
    color: rgb(233, 233, 233);
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0,0,0,0.244) 0%, rgba(42,42,42,0.840) 50%, rgba(42,42,42,1) 80%);
    /* background: linear-gradient(90deg, rgba(0, 0, 0, 0.757) 0%, rgba(0, 0, 0, 0.669) 27%, rgba(0, 0, 0, 0.151) 100%); */
    transition-property: transform;
    transition: 0.3s ease;
}

.campoResumen:hover::before {
    left: 0;
}

.campoResumen:hover {
    transform: scale(1.018);
}