.button-volver {
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  background: white !important;
  color: rgba(115, 115, 115, 1) !important;
  margin: 0px !important;
  text-transform: none !important;

  &:hover {
    border: none;
  }
}

.button-volver .pi {
  width: 30px;
  margin: 0px !important;
}

.div-datos-proyectos {
  height: 220px;
  border: 0.3px solid rgba(164, 159, 159, 1);
  border-radius: 8px;
  padding-left: 30px;
  margin-top: 30px;
  align-content: center;
}

.es-cliente-datosproyectos {
  display: flex;
  margin-left: 15px;
  margin-top: 8px;
}


.margin-media-right {
  margin-right: 20px;
}

.label-datos-proyectos {
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 600;
}

.label-si-no {
  font-family: 'Montserrat' !important;
  font-weight: 400;
  font-size: 16px;
}

.primer-div-datos-proyectos {
  max-width: 1400px;
  display: flex;
}

.p-dropdown .p-dropdown-label.p-placeholder{
  padding-right: 0px !important;
}

.datos-proyecto-2 {
  display: flex;
  max-width: 800px;
  margin-top: 40px;
}

.input-cotizador{
  font-family: 'Montserrat' !important;
  font-weight: 400 !important;
  font-style: italic !important;
  font-size: 16px !important;
  width: 340px !important;
}

.select-datos-proyecto{
  width: 268px;
  font-family: 'Montserrat' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.select-datos-proyecto-2 {
  width: 268px;
  font-family: 'Montserrat' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.datos-proyecto-2 {
  display: flex;
  max-width: 1400px !important;
  margin-top: 40px;
}

@media(max-width: 1530px) {

  .primer-div-datos-proyectos {
    max-width: 1200px;
    display: flex;
  }

  .input-cotizador{
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
    font-style: italic !important;
    font-size: 16px !important;
    width: 250px !important;
  }
  
  .select-datos-proyecto{
    width: 230px;
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
    font-size: 12px !important;
  }
  
  .select-datos-proyecto-2 {
    width: 230px;
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }

  .datos-proyecto-2 {
    display: flex;
    max-width: 1300px !important;
    margin-top: 40px;
  }

  
}
@media(max-width: 1350px) {

  .primer-div-datos-proyectos {
    max-width: 1200px;
    display: flex;
  }

  .input-cotizador{
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
    font-style: italic !important;
    font-size: 16px !important;
    width: 220px !important;
  }
  
  .select-datos-proyecto{
    width: 200px;
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
  
  .select-datos-proyecto-2 {
    width: 220px;
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }

  .datos-proyecto-2 {
    display: flex;
    max-width: 800px !important;
    margin-top: 40px;
  }
  .margin-media-right {
    margin-right: 10px;
}
  
}

@media(min-width: 1580px) {

  .datos-proyecto-2 {
    display: flex;
    max-width: 1500px !important;
    margin-top: 40px;
  }
}

@media(max-width: 1400px) {
  .datos-proyecto-2 {
    display: flex;
    max-width: 1200px !important;
    margin-top: 40px;
  }

  .primer-div-datos-proyectos {
    max-width: 1200px;
    display: flex;
  }

}

.margin-radio-buttons {
  margin-right: 20px;
}

@media (max-width: 1220px){
  .div-datos-proyectos{
    padding-left: 10px;
  }
}
@media (max-width: 1193px) {
  .select-datos-proyecto {
    width: 180px;
  }

  .div-datos-proyectos {
    height: 220px;
    border: 0.3px solid rgba(164, 159, 159, 1);
    border-radius: 8px;
    padding-left: 10px;
    margin-top: 20px;
    align-content: center;
  }

  .margin-media-right {
    margin-right: 5px;
  }

  .margin-radio-buttons {
    margin-right: 10px;
  }

  .label-datos-proyectos {
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 600;
  }

}

.button-continuar-datos-proyectos {
  width: 138px;
  height: 30px;
  font-family: 'Montserrat' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-left: 20px !important;
}

.volver-footer {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.estile-step {
  border: 0.3px solid rgba(164, 159, 159, 1);
  border-radius: 8px;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.p-error-datos-proyecto {
  font-size: 11px;
  color: red;
  position: absolute;
  bottom: -15px
}


.p-steps .p-steps-item.p-highlight .p-steps-number, .p-steps .p-steps-item .p-menuitem-link .p-steps-number, .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  font-size: 15px !important;
}