.div_dropdown-informes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dropdown-informes {
  width: auto;
  min-width: 138px;
  /* height: 10%; */
  margin-top: 2px;
  align-items: center;

  .p-dropdown-label.p-inputtext {
    width: auto;
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 4px;
  }
}

.dropdown-informes .pi-chevron-down {
  padding-top: 1px;
  color: #495057;
}

.cc-recursos-column:hover {
  transform: scale(1.35);
}

.descarga-matriz{
display: flex;
justify-content: space-between;
align-items: center;
}

.button-descarga-matriz{
font-family: Montserrat !important;
font-size: 14px !important;
font-weight: 700 !important;
line-height: 17.07px !important;
width: 316px;
height: 33px;
padding: 0 16px 0 0!important;
display: flex !important;
justify-content: center;
text-transform: capitalize !important;
}