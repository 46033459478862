.hidenPanel .p-tabview-nav {
    display: none;
}

.validateItem {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-block: 5px 6px;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
}
.skillContainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
}
