.title-modal-guardar-coti{
    font-family: 'Montserrat';
    font-size: 21px;
    font-weight: 700;
    line-height: 38px;  
    margin: 10px 0 16px 0;
}

.p-modal-guardar-coti{
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
}

#exclamation-triangle-modal{
    font-size: 28px;
    margin-top: 5px;
    padding-right: 16px;
}

.p-no-modal{
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    color: #0A5EFA;
    margin: 12px 30px 0 0;
    cursor: pointer;
}

.button-salir-modal{
    width: 154px;
    color: #FFF;
    font-family: 'Montserrat' !important;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    height: 40px;
    width: 140px;
    cursor: pointer;
    border-radius: 4px;

    &:hover{
        background-color: #4f46e5;
    }
}