#custom-horas-modal .p-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#custom-horas-modal .pi {
  width: auto;
  height: auto;
}

#custom-horas-modal .p-dialog-content .dialog-title {
  margin-top: 33px;
  margin-bottom: 7px;
  line-height: 38px;
}

#custom-horas-modal .p-dialog-content .dialog-subtitle {
  font-size: 16px;
  margin-bottom: 67px;
  text-align: center;
}

#custom-horas-modal .boton-footer-cancelar-modal {
  margin-right: 16px;
  padding: 10px 12px 10px 12px;
  width: auto;
  line-height: 20px;
}

#custom-horas-modal .boton-footer-aplicar-modal {
  padding: 10px 14.5px 10px 14.5px;
  width: auto;
  line-height: 20px;
}
