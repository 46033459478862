.buttons-tarifario {
  width: 100%;
  height: 48px;
  color: rgba(73, 69, 79, 1);
  background: rgba(254, 247, 255, 1);
  font-family: 'Montserrat';
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.1px;
  cursor: pointer;

  &:hover {
    background: rgba(254, 247, 255, 1);
  }
}

.buttons-tarifario.selected {
  background: rgba(103, 80, 164, 0.12);
  position: relative;
  color: rgba(103, 80, 164, 1);
}

.buttons-tarifario.selected::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: calc(50% - 94.5px);
  width: 189px;
  height: 3px;
  background-color: rgba(103, 80, 164, 1);
}

.button-borrar {
  width: 40px !important;
  height: 32px !important;
  border: 1px solid rgba(89, 96, 120, 0.2) !important;
  border-radius: 6px !important;
}

.busca{
  left: 0.3rem !important;
  top: 0.7rem !important;
  color: #6c757d;
}

@media(max-width: 1400px) {
  .buttons-tarifario {
    width: 100%;
    height: 48px;
    color: rgba(73, 69, 79, 1);
    background: rgba(254, 247, 255, 1);
    font-family: 'Montserrat';
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 0.1px;
    cursor: pointer;
  }
}

.p-tarifario {
  font-family: 'Montserrat';
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 22px;
}

.input-buscar-tarifario {
  width: 320px;
  height: 35px;
  font-family: 'Montserrat';
  font-weight: 400 !important;
  font-size: 16px !important;
}

.tablas-tarifario {
  font-size: 16px !important;
  font-weight: 400;
  font-family: 'Montserrat';
}

.tablas-tarifario .p-column-header-content{
  justify-content: center !important;
}

.th-tabla-tarifario {
  font-size: 16px !important;
  font-weight: 600;
  font-family: 'Montserrat';
}

.custom-empty-message {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 20px;
  color:rgba(0, 0, 0, 1);
  font-size: 18px;
  text-align: center;
  padding: 20px;
}

.custom-empty-message img {
  display: block;
  margin: 0 auto 10px; 
  max-width: 150px; 
}

.version{
  font-family: 'Montserrat' !important;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.63);
  line-height: 22px;
}