.notfound {
    text-align: center;
    margin-top:200px;
    color: #3d6bb3;
    font-weight: bold;
    font-size: large;
}
.inicio{
    margin-top: 5px;
    color: #3d6bb3;
    font-weight: bold;
    font-size: small
}

#boton{
 margin-top: 10px;
 align-items: center;
 color: white;
 background-color: #3d6bb3;
 border-radius: 12px;
 width: 40px;
 padding-bottom: 7px;
 height: auto;
 border: none;
 transition: box-shadow 0.2s ease;
 box-shadow: 0px 0px 8px rgba(19, 141, 255, 0.993);
}

#boton:hover {
    transform: scale(1.1);
  }