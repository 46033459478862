.talento-Container {
  width: 100%;
  height: 304px;
  margin: 10px 0px 0px 0px;
  gap: 0px;
  border: rgba(0, 0, 0, 0.12) 1px solid;
}

@media (min-height: 800px) {
  .talento-Container {
    height: 460px;
  }
}

@media (min-height: 899px) {
  .talento-Container {
    height: 550px;
  }
}
.head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.title-talento {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
}

.bottons-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.add-item {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 6px 12px 6px 12px !important;
  width: 206px !important;
  height: 36px !important;
  font-family: "Montserrat" !important;
}

#plus-continuar-seleecion-talento {
  margin: 0px -2px 0px -12px;
}

.trash-button-container {
  border-radius: 50%;
  padding: 2px;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.trash-disabled {
  cursor: default;
}

.trash-button-container:active {
  background-color: #e6e6e6;
}

.table-header {
  background-color: #f6f6f6 !important;
  height: 56px;
  justify-content: center;
  vertical-align: middle;
  text-align: center !important;
  width: 120px;
}

.table-header-pancil {
  background-color: #f6f6f6 !important;
  height: 56px;
  justify-content: center;
  vertical-align: middle;
  text-align: center !important;
  width: 20px;
}

.p-column-title {
  font-family: "Montserrat" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-align: left !important;
  letter-spacing: 0.1px;
}

.p-column-header-content {
  justify-content: start !important;
  padding-left: 7px;
}

.p-checkbox .p-checkbox-box {
  border: rgba(0, 0, 0, 0.4) 1.8px solid !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 0% !important;
}

.p-editable-column {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.table-scrollable-container {
  height: 250px;
  overflow-y: auto;
  /* overflow-x: hidden; */
}

@media (min-height: 800px) {
  .table-scrollable-container {
    height: 400px;
  }
}

@media (min-height: 899px) {
  .table-scrollable-container {
    height: 492px;
  }
}

/* Custom class for datatable */
.custom-datatable .p-datatable table {
  width: 100% !important;
  table-layout: auto !important;
}

/* Custom class for inputs */
.custom-datatable .custom-datatable .p-dropdown,
.custom-datatable .p-inputnumber {
  width: 100% !important;
}

.title-container {
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.counter-container {
  display: flex;
  width: 210px;
  height: 24px;
  border-radius: 8px;
  background: #000;
  align-items: center;
  justify-content: center;
}

.counter-text {
  font-weight: 600;
  color: #fff;
  font-family: "Montserrat" !important;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .table-scrollable-container {
    width: 100%;
    overflow-x: auto;
  }

  .table-header,
  .table-header-pancil {
    width: auto !important;
  }

  .custom-datatable .p-inputtext,
  .custom-datatable .p-dropdown,
  .custom-datatable .p-inputnumber {
    width: auto !important;
  }
}

.tabla-seleccion-talento > tr > td {
  border: none !important;
}
