.div-1-habilidades {
  display: flex;
  align-items: center;
}

.p1-habilidades {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.26px;
}

.p2-habilidades {
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 19.5px;
  text-align: left;
  padding: 0 1.25rem 0 1.25rem;
}

.p1-modal-habilidades {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: rgb(45, 45, 45, 1);
  padding: 14px 0px 10px 20px;
}

.custom-modal .p-dialog-header {
  padding: 1px !important;
  border-bottom: 1px solid rgba(236, 236, 236, 1) !important;
}

.custom-modal .p-dialog-header-icons {
  padding: 10px 13px 5px 10px !important;
}

.custom-modal .p-dialog-content {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
}

.button-modal-habilidades {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 19.5px !important;
  color: rgba(234, 234, 234, 1) !important;
  background: var(--Color, rgba(4, 74, 205, 1)) !important;
  width: 107px;
  height: 32px;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  text-transform: capitalize !important;
}

.h2-modal-habilidades {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 300;
  line-height: 17.07px;
  margin-bottom: 3px;
}

.h2-modal-habilidades-certi {
  font-family: Montserrat;
  font-size: 16px;
  color: rgba(45, 45, 45, 1);
  font-weight: bold;
}

.footer-modal-habilidades {
  margin-top: auto;
  padding: 15px 15px 15px 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid rgba(236, 236, 236, 1);
}

.footer-card-habilidades {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 1.25rem;
  text-align: center;
  border-top: 0.5px solid rgba(204, 204, 204, 1);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  color: rgba(45, 45, 45, 1);
  cursor: pointer;
}

.filtros-select {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background: rgba(217, 217, 217, 1);
  font-size: 14px;
  margin-right: 6px;
  margin-top: 5px;
  text-align: center;
  padding: 2px 6px 2px 11px;
  font-family: Montserrat;
  font-weight: 400 !important;
  color: rgba(28, 27, 31, 1);
}

.custom-icon-habilidades {
  width: 30px !important;
}

.body-card-habilidades {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  padding: 0 1.25rem 0 1.25rem;
}

.p-body-card-habilidades {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.p-body-card-habilidades-border {
  border-top: 0.5px solid rgba(204, 204, 204, 1);
}

.custom-card-habilidades .p-card-body {
  padding: 0px !important;
}

.button-modal-habilidades-cancelar {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19.5px;
  color: rgba(117, 117, 117, 1) !important;
  background-color: white !important;
  border: none !important;
  margin-right: 20px !important;
  text-transform: capitalize !important;
}

.p-body-card-habilidades-certi {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 17.07px;
  padding-top: 8px;
}

.body-card-habilidades-certi {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  padding: 0 1.25rem 0 1.25rem;
  padding-bottom: 2px;
}

.dropdown-custom-disabled {
  background-color: rgba(194, 194, 194, 1) !important;
}

.file-upload-skills {
  padding: 0 !important;
}
.file-upload-skills .p-fileupload-filename {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-skills .p-multiselect-item {
  font-family: "Montserrat" !important;
}

