* {
  margin: 0;
  padding: 0;
}
:root {
  --primary-blue: #3d6bb3;
  --low-gray: #f4f4f4;
  font-family: var(--main-font);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 8px #fffffff1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #838383;
  border-radius: 10px;
}

h4,
h3 {
  color: var(--button-primary-blue);
  font-family: var(--main-font);
}

.button-icon .pi {
  color: var(--button-primary-blue) !important;
  background-color: transparent !important;
}

.button-icon:hover .pi {
  color: var(--button-primary-hover) !important;
  background-color: transparent !important;
}

button,
.p-button {
  background-color: var(--button-primary-blue);
  border: none;
  text-transform: uppercase;
  font-size: 0.85rem;
  padding: 0;
}

button:hover,
.p-button:hover {
  background-color: var(--button-primary-hover);
}

button:disabled,
.p-button.p-disabled {
  background-color: var(--button-primary-disabled) !important;
}

.p-button:focus {
  box-shadow: none;
}

.p-button.p-button-outlined {
  color: #3d6bb3;
}

.p-unselectable-text {
  width: calc(100% / 3);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-radius: 0;
  background: #f6f6f6;
  color: #b3b3b3;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: var(--primary-blue);
  background-color: var(--low-gray);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.5rem 12vw;
}

.p-tabview .p-tabview-panels {
  padding: 1px 0 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background: #e5e6e7;
  border-color: #3d6bb3;
  color: #3d6bb3;
}

.p-tabview-title {
  font-size: 13px;
  font-weight: 600;
}

.p-component {
  font-size: 0.9em;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-pages .p-paginator-page {
  height: 2rem;
  min-width: 2rem;
  background-color: transparent !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: #3d6bb3;
  border-bottom: 2px solid;
  border-radius: 0;
}

.p-datatable-wrapper {
  max-height: calc(100vh - 12rem);
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 2px;
  justify-content: center !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 5px;
  text-align: center;
  justify-content: center;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 2rem;
  height: 2rem;
}

.p-speeddial {
  position: initial;
}

.p-speeddial-action {
  width: 2rem;
  height: 2rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding-bottom: 10px;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding-top: 0;
}

.p-column-filter-menu {
  margin-left: 0;
}

.pi {
  font-size: 16px;
  width: 50px;
  height: 30px;
}

i.pi.pi-fw {
  width: 60px;
  cursor: pointer;
}

.p-float-label label {
  top: 16px;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -8px;
  left: 1px;
  font-size: 11px;
  padding: 0 4px 0 4px;
}

.p-fluid .p-dropdown .p-dropdown-label .p-inputtext {
  padding: 6px 12px;
}

.p-dialog .p-dialog-footer {
  background-color: #efefef !important;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
  min-width: 38px;
  padding: 0 15px;
  border-radius: 30px;
  height: 25px;
}

.pi-exclamation-triangle:before {
  vertical-align: initial !important;
}

.p-column-title {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: #6e6e6e;
  font-family: var(--main-font);
}

.p-datatable .p-column-header-content {
  justify-content: center;
}

.p-column-filter-menu-button:focus {
  box-shadow: none;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  gap: 10px;
}

.p-dialog .p-dialog-header,
.p-dialog-content {
  background-color: var(--background-grey) !important;
  padding: 10px 1.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}

.p-link:focus {
  box-shadow: none;
}

/* FORMS */

.row-container {
  display: flex;
  gap: 10px;
  margin-top: 1.8rem;
}

.input-container {
  width: 100%;
  position: relative;
}

.input-container.small {
  width: 200px;
}

.input-container.medium {
  width: 1000px;
}

.p-error {
  position: absolute;
  bottom: -16px;
  left: 5px;
  font-size: 11px;
}

/* UPLOADER */

.p-fileupload.p-fileupload-advanced.p-component {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f8f9fa;
  border: 1px solid #dedede;
  border-radius: 6px;
}

.p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  padding: 6px;
  border: none;
  color: #343a40;
  border-bottom: 0 none;
}

span.p-button.p-fileupload-choose.p-component.p-button-icon-only.custom-choose-btn.p-button-rounded.p-button-outlined {
  border: none;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  margin: 0;
}

.p-fileupload .p-fileupload-content {
  padding: 10px;
  width: 100%;
}

.p-fileupload .p-fileupload-content > p.m-0 {
  text-align: center;
}

.pi.pi-fw.pi-paperclip {
  font-size: 18px;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f1f1f1;
}

.button-estado-modal {
  background-color: transparent;
}

.button-estado-modal.green {
  color: green;
}

.button-estado-modal.red {
  color: red;
}

.button-estado-modal:hover {
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
}

.p-dropdown-label.p-inputtext,
.p-dropdown-clear-icon.pi.pi-times {
  font-size: 12px;
}

.pi:before {
  position: relative;
}

.p-button.p-button-icon-only {
  width: 35px;
  /* height: 35px; */
}
