.container {
  padding: 0 1.4rem 0 5rem;
  font-family: var(--main-font);
}

.encabezado {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  flex-wrap: wrap;
}

.titulo {
  white-space: nowrap;
  margin: 10px 0;
  font-size: 1rem;
}

.imagen_encabezado {
  height: 28px;
  object-fit: contain;
}

.titulo_novedades {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 10px; */
  margin: 0.5rem 0 0.2rem 0;
  flex-wrap: wrap;
}

.titulo_novedades div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.containerSwitch {
  display: flex;
  /* justify-content: flex-end; */
  flex-wrap: wrap;
}

.titulo_novedades > h3 {
  margin: 7px 0;
}

.titulo_novedades .switch span {
  font-size: 14px;
}

.tabla_novedades {
  margin-left: 2cm;
}

.boton_mas {
  width: 40px !important;
  height: 40px !important;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: center;
}

.card {
  font-family: var(--main-font);
}

.p-avatar.p-avatar-lg {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.3rem;
  padding-bottom: 2px;
}

.p-inputtext {
  font-size: 12px;
  height: 30px;
}

.p-datatable .p-datatable-tbody > tr > td {
  font-size: 12px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent !important;
}

.p-unselectable-text {
  width: calc(100% / 2) !important;
}

.p-tabview .p-tabview-nav li {
  text-align: center !important;
}

.p-tabview-nav-link {
  display: block !important;
  line-height: 13px;
}

.titulo_solapa .p-tabview-title {
  font-size: 16px;
}

.titulo_solapa.p-tabview-selected > a {
  color: rgb(245, 245, 245);
  font-weight: 620;
  background-color: #3d6bb3 !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: white !important;
}

.titulo_solapa .p-tabview-title:hover {
  font-weight:650;
}

.p-tabview .p-tabview-nav li:not(.p-tabview-selected) .p-tabview-nav-link {
  color: rgba(21, 67, 194, 0.979);
  background-color: rgb(236, 236, 236);
}

.p-tabview .p-tabview-nav .p-tabview-nav-link:hover {
  background-color: #3d6bb3;
}

.PatagonianFest-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.go-toPatagonianFest {
  /* background-image: url('../../pages/PatagonianFest/assets/button.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-size: 3rem;
  width: 94%;
  height: 8.832rem;
  border-radius: 30px;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -5px;
  left: 65px;
}

@media only screen and (max-width: 1150px) {
  .go-toPatagonianFest {
    width: 85%;
    font-size: 1.7rem;
    height: 5.832rem;
  }
}
