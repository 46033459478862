.login-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.background-container {
  background: rgb(8, 75, 250);
  background: linear-gradient(
    145deg,
    rgba(8, 75, 250, 1) 0%,
    rgba(26, 208, 251, 1) 77%,
    rgba(44, 238, 204, 1) 100%
  );
  width: 50%;
  align-items: center;
  display: flex;
}

.background-container p {
  font-family: var(--main-font);
  color: var(--white-color);
  margin: 0 auto;
  font-size: 30px;
  font-weight: bold;
}
.googlelogin-conteiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  padding: 170px 0;
}

.img_login {
  width: 35%;
}

.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-container {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #1a88e9, #2fdce2ec);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.googlelogin-conteiner h1 {
  color: var(--primary-color);
}

@media (max-width: 800px) {
  .login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
  }

  .background-container {
    background: rgb(8, 75, 250);
    background: linear-gradient(
      145deg,
      rgba(8, 75, 250, 1) 0%,
      rgba(26, 208, 251, 1) 77%,
      rgba(44, 238, 204, 1) 100%
    );
    width: 100%;
    height: 50%;
    align-items: center;
    display: flex;
  }

  .background-container p {
    font-family: var(--main-font);
    color: var(--white-color);
    margin: 0 auto;
    font-size: 40px;
    font-weight: bold;
  }

  .googlelogin-conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 50%;
    padding-top: 50px;
  }
}
