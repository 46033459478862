.field-proveedor-container {
    text-align: start;
}

.field-proveedor-container>h4 {
    margin: 10px 0;
}

.final-submit {
    display: flex;
    gap: 10px;
    margin-top: 1.5rem;
}

.final-submit>button {
    margin: 0 !important;
}

.field-proveedor-container .p-button {
    margin-bottom: 1.5rem;
    font-size: 13px;
    border-radius: 30px;
}